import React, { createContext, useCallback, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

function AuthProvider({ children, onLoginSuccess }) {
  const [user, setUser] = useState(() => {
    const userData = Cookies.get('user');
    return userData ? JSON.parse(userData) : null;
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    googleLogout();
    Cookies.remove('user');
    setUser(null);
  }, []);

  const loginWithRedirect = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoading(true);
      setError(null);
      try {
        const userInfo = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        });

        console.log('user', JSON.stringify(userInfo.data));
        Cookies.set('user', JSON.stringify(userInfo.data), { expires: 1/6 }); // Set cookie to expire in 4 hours
        setUser(userInfo.data);
        if (onLoginSuccess) {
          onLoginSuccess(); // Callback after successful login
        }
      } catch (error) {
        console.error("Error during login:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    onError: (errorResponse) => {
      console.error("Login error:", errorResponse);
      setError(errorResponse);
    },
  });

  useEffect(() => {
    const checkAuth = () => {
      const userData = Cookies.get('user');
      if (!userData) {
        setLoading(false);
        navigate('/login');
      } else {
        setUser(JSON.parse(userData));
        setLoading(false);
      }
    };

    checkAuth();
  }, [navigate]);

  // Auto-logout after 4 hours
  useEffect(() => {
    const fourHoursInMilliseconds = 4 * 60 * 60 * 1000;
    let logoutTimer;

    if (user) {
      logoutTimer = setTimeout(logout, fourHoursInMilliseconds);
    }

    return () => clearTimeout(logoutTimer); // Clear the timeout if the component unmounts or user logs out
  }, [user, logout]);

  return (
      <AuthContext.Provider value={{ user, loading, error, loginWithRedirect, logout }}>
        {loading ? <div>Loading...</div> : children}
      </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
  onLoginSuccess: PropTypes.func,
};

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };