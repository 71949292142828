import React from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider, useAuth } from './auth';
import Header from './Components/Header';
import AppRoutes from './Routes/Routes';
import ErrorBoundary from './Components/ErrorBoundary';
import './App.css';
import {GOOGLE_CLIENT_ID} from "./constants";


function AuthProviderWithNavigate({ children }) {
    const navigate = useNavigate();

    const handleLoginSuccess = () => {
        navigate('/');
    };

    return (
        <AuthProvider onLoginSuccess={handleLoginSuccess}>
            {children}
        </AuthProvider>
    );
}

function AppContent() {
    const { user, logout } = useAuth();

    return (
        <ErrorBoundary>
            <div className="app-container">
                <Header user={user} logout={logout} />
                <main className="main-content">
                    <AppRoutes />
                </main>
            </div>
        </ErrorBoundary>
    );
}

function App() {
    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <Router>
                <AuthProviderWithNavigate>
                    <AppContent />
                </AuthProviderWithNavigate>
            </Router>
        </GoogleOAuthProvider>
    );
}

export default App;